<template>
  <div class="container">
    <div v-if="exit" class="container bg-light boxdanger">
      <h1>{{ text_here }}</h1>
      <button type="button" class="btn btn-danger" v-on:click="reloadPage">
        {{ text_button }}
      </button>
    </div>

    <div v-if="headphone" class="container bg-light boxdanger">
      <h1>Wait!</h1>
      Use Headphones For Better Experience
    </div>

    <div v-if="card_birthday">
      <img
        src="..\assets\love.jpg"
        alt="Girl in a jacket"
        style="width: 80%; height: 80%"
      />
    </div>

    <div class="container" v-if="continueshow">
      <h1>Hey Farrah,</h1>
      <hr />
      <div class="container bg-light boxnew form-check">
        <form>
          <p>
            <span>Do you want to see something? </span>
          </p>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input
                  type="radio"
                  aria-label="Radio button for following text input"
                  v-model="question1"
                  value="yes"
                  v-on:change="QuestionOne"
                  name="q"
                  id="q1.1"
                />
              </div>
            </div>
            <input
              type="text"
              class="form-control"
              aria-label="Text input with radio button"
              readonly
              value="Yes, show me 😁"
            />
          </div>

          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input
                  type="radio"
                  aria-label="Radio button for following text input"
                  v-model="question1"
                  value="no"
                  v-on:change="QuestionOne"
                  name="q"
                  id="q1.2"
                />
              </div>
            </div>
            <input
              type="text"
              class="form-control"
              aria-label="Text input with radio button"
              readonly
              value="No, I don't want 😒"
            />
          </div>
          <br />
        </form>
      </div>

      <br />
      <div v-if="show_q2" class="container bg-light boxnew form-check">
        <div>
          <p>
            <span>But, answer this question first :)</span>
          </p>
        </div>
        <div>
          <span>What is today's date?</span><br />

          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input
                  type="radio"
                  aria-label="Radio button for following text input"
                  v-model="question2"
                  value="yes"
                  name="q1"
                  v-on:change="QuestionTwo"
                  for="q2.1"
                />
              </div>
            </div>
            <input
              type="text"
              class="form-control"
              aria-label="Text input with radio button"
              readonly
              value="21 Feb 2021 😝"
            />
          </div>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input
                  type="radio"
                  aria-label="Radio button for following text input"
                  v-model="question2"
                  value="no"
                  name="q1"
                  v-on:change="QuestionTwo"
                  for="q2.2"
                />
              </div>
            </div>
            <input
              type="text"
              class="form-control"
              aria-label="Text input with radio button"
              readonly
              value="28 May 2021 🤭"
            />
          </div>
          <br /><br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      question1: "",
      show_q2: false,
      exit: false,
      continueshow: true,
      try_again: "",
      card_birthday: false,
      headphone: false,
      text_here: "",
      text_button: "",
      source: ["..\\assets\\love.jpg", "..\\assets\\logo.png"],
    };
  },
  methods: {
    QuestionOne() {
      if (this.question1 == "yes") {
        this.show_q2 = true;
        this.exit = false;
      } else {
        this.show_q2 = false;
        this.exit = true;
        this.continueshow = false;
        this.text_here = "Hmmmmmmm 😭";
        this.text_button = "Choose yes, pleaseee!";
      }
    },
    QuestionTwo() {
      if (this.question2 == "yes") {
        this.continueshow = false;
        this.headphone = true;
        var audio = new Audio(
          "https://media.singing-bell.com/download_mp3.php?mp3=https%3A//media.singing-bell.com/wp-content/uploads/2016/03/Happy-Birthday-Song-Singing-Bell.mp3"
        );
        setTimeout(
          () => (
            (this.card_birthday = true), (this.headphone = false), audio.play()
          ),
          2500
        );
        audio.volume = 0.2;
      } else {
        this.continueshow = false;
        this.exit = true;
        this.text_here = "You choose the wrong answer 🙁";
        this.text_button = "I give you one more chance....";
      }
    },
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>

<style scoped>
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

h1 {
  font-weight: bold;
}

.boxnew {
  border-radius: 20px 50px;
  margin-top: 3%;
  padding-top: 3%;
  box-shadow: 5px 10px #d4d0d0;
}

.boxdanger {
  border-radius: 10px 10px;
  color: #990d0d;
  margin-top: 3%;
  padding-top: 3%;
  padding-bottom: 3%;
  box-shadow: 5px 10px #e78c8c;
  background-color: rgba(241, 30, 30, 0.288) !important;
}
</style>
